
import React, { useEffect, useState } from 'react';

export default function Prestation(props) {

  return (<>
    <div class="">
      Prestation
    </div>
  </>)
}