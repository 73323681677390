
import React, { useEffect, useState } from 'react';

export default function Message(props) {

  return (<>
    <div class="">
      Messages
    </div>
  </>)
}